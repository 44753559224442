import React, { useState, useEffect, useRef } from "react"
import ModalVideo from "react-modal-video"
import { Modal } from "react-bootstrap"
import "react-modal-video/scss/modal-video.scss"
import { createPortal } from "react-dom"
import { Link } from "gatsby"
import ReactPlayer from "react-player"
import { isIOS, isMobileOnly, isBrowser, isMobile } from "react-device-detect"
const PlayVideo = props => {
  const vidRef = useRef(null)
  const [mute, setMute] = useState(true)
  if (props.isOpen) {
    const url = typeof window !== "undefined" ? window.location.href : ""
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Video Play Btn",
      formType: url,
      formId: "Video Play",
      formName: "Video",
      formLabel: "Video",
    })
  }
  return (
    <React.Fragment>
      <Modal
        show={props.isOpen}
        onHide={e => props.isCloseFunction(false)}
        dialogClassName="modal-video"
        aria-labelledby="example-custom-modal-styling-title"
        backdropClassName="video-backdrop"
        className="area-guide-full-video-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ReactPlayer
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            autoplay
            ref={vidRef}
            // onPlay={trackerVideo(props.title)}
            url={props.videoId}
            modestbranding="1"
            controls={isMobile && isIOS ? true : true}
            muted={isMobile && isIOS ? mute : false}
            autoplay={true}
            playsinline={true}
            playing={props.isOpen}
            onEnded={() => {
              props.isCloseFunction(false)
            }}
            width="100%"
            height="100%"
            previewTabIndex={0}
            light={false}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default PlayVideo
